//
// Adnet Corp1 Theme
// Adnet Communications Inc. 2014
// _cms.scss
//

.hide {display: none}
.hide.uk-show {display: block}

/* Secure login page */
.login {background: $color-white}

/* Admin bar/panel */
#admin-panel {position: fixed; opacity: 0; display: none; left: 0; bottom: 0; z-index: 1001; width: 100%; min-height: 30px; background-color: #188cb7; color: #fff; font-family: $helvetica;}
#admin-panel {line-height: 18px;}

/* Admin button */
#admin-button {position: fixed; right: 0; bottom: 20px; z-index: 1000; cursor: pointer; width: 50px; height: 50px; color: #fff; background: #188cb7; @include border-topleft-radius(10px); @include border-bottomleft-radius(10px); transition: all .3s ease-in-out}
#admin-button a {color: $color-white; font-size: 13px; display: block; font-weight: bold}
#admin-button a[class^='uk-icon'] {line-height: 2.2}
#admin-button i {font-size: 20px; line-height: 48px; margin: 0 10px}
#admin-button a span {line-height: 30px; padding: 0 5px}
#admin-button:hover {right: 0}

/* Admin items */
#admin-panel .uk-subnav>li {line-height: 1 !important;}
#admin-panel .uk-subnav>li>a {color: rgba(255,255,255,0.8) !important;}
#admin-panel .uk-subnav>li>a:hover {color: $color-white !important}
#admin-panel .uk-subnav-line>li:nth-child(n+2):before {margin-top: 9px; border-left: 1px solid lighten( $color-primary, 70% ) !important}

/* Clndr Settings */
   /* Element */
   #calendar {position: relative; float: left; width: 100%; overflow: hidden}

   /* Calendar */
   .clndr {}
   .clndr-area {}

   /* Controller */
   .clndr-controls {position: relative}
   .clndr-control-button {position: absolute; z-index: 2; cursor: pointer; width: 25px; height: 25px; top: 20px}
   .clndr-control-button.clndr-previous-button {right: 30px; background-position: 0 -64px}
   .clndr-control-button.clndr-previous-button:hover {background-position: 0 -64px}
   .clndr-control-button.clndr-next-button {background-position: -25px -64px; left: auto; right: 0px}
   .clndr-control-button.clndr-next-button:hover {background-position: -25px -64px}
   .clndr-today-button {cursor: pointer; color: #333; background: #fed24f}
   .clndr-today-button:hover {color: #fff; background: #fed24f}
   .clndr-control-button span {}
   .clndr-controls .month {position: relative; text-align: center}

   /* Table display */
   .clndr-table {margin: 10px 0}
   .clndr-table td {text-align: center; font-size: 13px; width: 14%; height: 32px; background: #edecec}
   .clndr-table .header-day {color: #0063ac; height: 32px}
   .clndr-table .day {color: #697279}
   .clndr-table .adjacent-month.last-month, .clndr-table .adjacent-month.next-month {background: #eee; color: #999}
   .clndr-table .today {background: #fed24f; color: #333}
   .clndr-table .event {background: $color-primary; color: #fff}
   .clndr-table .header-day.first, .clndr-table .day.first {border-left: 0}
   .clndr-table .header-day.last, .clndr-table .day.last {border-right: 0}
   .clndr-table tr.last td {border-bottom: 0}

   /* Event listing */
   .event-listing {}
   .event-listing i[class^='uk-icon'] {line-height: 1.4} 
   .event-listing .event-item {margin-left: 12px}
   .event-listing .event-icon {margin-right: 5px; float: left}
   .event-listing h3 {margin: 0 0 5px 0}
   li.pdf a, li.zip a, li.nr a {display: none}
   .post h3 {color:#66C7E2;}