@charset "UTF-8";
/*
 *
 * Design by Adnet Communications Inc.
 * Implementation by Adnet Communications Inc.
 * By Rudy Affandi
 * (c) 2015
 *
 */
/* Set up variables */
/* SASS variables */
/* Path */
/* Font stacks */
/* Font sizes */
/* Site-specific colour palette */
/* Additional colour palette */
/* Width and height settings */
/* Breakpoints */
/* SASS Mixins */
/* Vertical align center everything */
/* Text shadow */
/* Opacity and colour */
/* Import customized sass */
/*
 * Filename: _plugins.scss
 * Adnet Communications Inc. 2014
 * by Rudy Affandi
 *
 */
/* Box-sizing */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  vertical-align: top; }

/* Transitions */
a {
  -webkit-transition: color .2s ease-in-out, background .2s ease-in-out;
  -moz-transition: color .2s ease-in-out, background .2s ease-in-out;
  transition: color .2s ease-in-out, background .2s ease-in-out; }

.greyscale {
  filter: url("../js/vendor/filters.svg#grayscale");
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: gray; }

.greyscale:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%); }

/* Stock quote-related */
.change-positive {
  color: #31bb5f; }

.change-negative {
  color: #fb3636; }

.change-neutral {
  color: #666; }

/* Case modifiers */
.uk-text-capitalize {
  text-transform: capitalize !important; }

.uk-text-uppercase {
  text-transform: uppercase !important; }

.uk-text-lowercase {
  text-transform: lowercase !important; }

.uk-text-italic {
  font-style: italic !important; }

/* panel padding */
.uk-panel-padding-small {
  padding: 5px !important; }

.uk-panel-padding-medium {
  padding: 10px !important; }

.uk-panel-padding {
  padding: 20px !important; }

.uk-panel-padding-large {
  padding: 35px !important; }

@media screen and (max-width: 480px) {
  /* panel padding */
  .uk-panel-padding-small {
    padding: 3px !important; }
  .uk-panel-padding-medium {
    padding: 5px !important; }
  .uk-panel-padding {
    padding: 10px !important; }
  .uk-panel-padding-large {
    padding: 15px !important; } }

/* CSS Multi column */
.two-col, .col-two {
  -moz-column-count: 2;
  -moz-column-gap: 40px;
  -webkit-column-count: 2;
  -webkit-column-gap: 40px;
  column-count: 2;
  column-gap: 40px; }

ul.col-two {
  -moz-column-count: 2;
  -moz-column-gap: 10px;
  -webkit-column-count: 2;
  -webkit-column-gap: 10px;
  column-count: 2;
  column-gap: 10px;
  margin: 0;
  padding: 0;
  list-style-position: inside; }

.three-col, .col-three {
  -moz-column-count: 3;
  -moz-column-gap: 40px;
  -webkit-column-count: 3;
  -webkit-column-gap: 40px;
  column-count: 3;
  column-gap: 40px; }

.keeptogether {
  display: inline-block;
  width: 100%; }

@media screen and (max-width: 480px) {
  .two-col, .col-two {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1; }
  .three-col, .col-three {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1; } }

/* Parsley */
input.parsley-success, select.parsley-success, textarea.parsley-success {
  color: #468847;
  background-color: #DFF0D8;
  border: 1px solid #D6E9C6; }

input.parsley-error, select.parsley-error, textarea.parsley-error {
  color: #B94A48;
  background-color: #F2DEDE;
  border: 1px solid #EED3D7; }

.parsley-errors-list {
  margin: 2px 0 3px;
  padding: 0;
  list-style-type: none;
  font-size: 0.9em;
  line-height: 0.9em;
  opacity: 0;
  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in; }

.parsley-errors-list.filled {
  opacity: 1; }

/* Superfish 1.7.4
.sf-menu,.sf-menu *{margin:0;padding:0;list-style:none}.sf-menu li{position:relative}.sf-menu ul{position:absolute;display:none;top:100%;left:0;z-index:99}.sf-menu>li{float:left}.sf-menu li:hover>ul,.sf-menu li.sfHover>ul{display:block}.sf-menu a{display:block;position:relative}.sf-menu ul ul{top:0;left:100%}.sf-menu{float:left;margin-bottom:1em}.sf-menu ul{box-shadow:2px 2px 6px rgba(0,0,0,.2);min-width:12em;*width:12em}.sf-menu a{border-left:1px solid #fff;border-top:1px solid #dFeEFF;border-top:1px solid rgba(255,255,255,.5);padding:.75em 1em;text-decoration:none;zoom:1}.sf-menu a{color:#13a}.sf-menu li{background:#fff;white-space:nowrap;*white-space:normal;-webkit-transition:background .2s;transition:background .2s}.sf-menu ul li{background:#AABDE6}.sf-menu ul ul li{background:#9AAEDB}.sf-menu li:hover,.sf-menu li.sfHover{background:#CFDEFF;-webkit-transition:none;transition:none}.sf-arrows .sf-with-ul{padding-right:2.5em;*padding-right:1em}.sf-arrows .sf-with-ul:after{content:'';position:absolute;top:50%;right:1em;margin-top:-3px;height:0;width:0;border:5px solid transparent;border-top-color:#dFeEFF;border-top-color:rgba(255,255,255,.5)}.sf-arrows>li>.sf-with-ul:focus:after,.sf-arrows>li:hover>.sf-with-ul:after,.sf-arrows>.sfHover>.sf-with-ul:after{border-top-color:#fff}.sf-arrows ul .sf-with-ul:after{margin-top:-5px;margin-right:-3px;border-color:transparent;border-left-color:#dFeEFF;border-left-color:rgba(255,255,255,.5)}.sf-arrows ul li>.sf-with-ul:focus:after,.sf-arrows ul li:hover>.sf-with-ul:after,.sf-arrows ul .sfHover>.sf-with-ul:after{border-left-color:#fff}
 */
/* Superfish normalizer */
/*
.sf-menu, .sf-menu * {margin: 0;padding: 0;list-style: none}
.sf-menu li {position: relative}
.sf-menu ul, .sf-menu .sf-mega {position: absolute;display: none;top: 100% ;left: 0;z-index: 99}
.sf-menu > li {float: left}
.sf-menu li:hover > .sf-mega, .sf-menu li.sfHover > .sf-mega,
.sf-menu li:hover > ul, .sf-menu li.sfHover > ul {display: block}
.sf-menu {float: left}
.sf-menu ul {box-shadow: 2px 2px 6px rgba(0, 0, 0, .2);min-width: 12em; *width: 12em}
.sf-menu a {padding: 0;text-decoration: none;zoom: 1}
.sf-menu a {}
.sf-menu li{white-space: nowrap; *white-space: normal;-webkit-transition: background.2s;transition: background.2s}
.sf-menu ul li {}
.sf-menu ul ul li {}
.sf-menu li:hover,.sf-menu li.sfHover{-webkit-transition:none;transition:none}
.sf-arrows.sf-with-ul {}
.sf-arrows.sf-with-ul:after {content: '';position: absolute;top: 50% ;right: 0;margin-top: 0;height: 0;width: 0}
.sf-arrows > li > .sf-with-ul:focus:after, .sf-arrows > li:hover > .sf-with-ul:after, .sf-arrows > .sfHover > .sf-with-ul:after {}
.sf-arrows ul.sf-with-ul:after {}
.sf-arrows ul li > .sf-with-ul:focus:after, .sf-arrows ul li:hover > .sf-with-ul:after, .sf-arrows ul.sfHover > .sf-with-ul:after {}
*/
.amChartsDataSetSelector {
  font-size: 12px;
  font-family: verdana,helvetica,arial,sans-serif; }

.amChartsPeriodSelector {
  font-size: 12px;
  font-family: verdana,helvetica,arial,sans-serif; }

.amChartsButtonSelected {
  background-color: #CC0000;
  border-style: solid;
  border-color: #CC0000;
  border-width: 1px;
  color: #FFFFFF;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 1px;
  outline: none;
  box-sizing: border-box; }

.amChartsButton {
  color: #000000;
  background: transparent;
  opacity: 0.7;
  border: 1px solid rgba(0, 0, 0, 0.3);
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 1px;
  outline: none;
  box-sizing: border-box; }

.amChartsCompareList {
  border-style: solid;
  border-color: #CCCCCC;
  border-width: 1px; }

.amChartsCompareList div {
  -webkit-box-sizing: initial;
  box-sizing: initial; }

.sf-menu {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none; }

.sf-menu .sf-mega {
  position: absolute;
  display: none;
  top: 100%;
  left: auto;
  z-index: 1; }

.sf-menu > li {
  float: left; }

.sf-menu li:hover > .sf-mega, .sf-menu li.sfHover > .sf-mega {
  display: block; }

.sf-menu > li > a {
  display: block;
  position: relative; }

.sf-menu a {
  display: block;
  position: relative; }

.sf-menu .sf-mega ul, .sf-menu .sf-mega ul ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.sf-menu ul ul {
  top: 0;
  left: 100%; }

.sf-mega-section {
  border-right: 0;
  margin: 0;
  width: 50%; }

/* Site Map */
.sitemap-nav {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0; }

.sitemap-nav > li {
  display: inline-block;
  vertical-align: top;
  margin: 0 20px 10px 0; }

.sitemap-nav > li {
  font-weight: bold; }

.sitemap-nav > li li {
  font-weight: normal; }

.sitemap-nav > li ul {
  list-style: none;
  margin: 0 0 0 20px;
  padding: 0; }

/* Custom uiKit grid */
/* 8 column grid */
.uk-width-large-1-8, .uk-width-medium-1-8, .uk-width-small-1-8, .uk-width-1-8 {
  width: 12.5%; }

.uk-width-large-2-8, .uk-width-medium-2-8, .uk-width-small-2-8, .uk-width-2-8 {
  width: 25%; }

.uk-width-large-3-8, .uk-width-medium-3-8, .uk-width-small-3-8, .uk-width-3-8 {
  width: 37.5%; }

.uk-width-large-4-8, .uk-width-medium-4-8, .uk-width-small-4-8, .uk-width-4-8 {
  width: 50%; }

.uk-width-large-5-8, .uk-width-medium-5-8, .uk-width-small-5-8, .uk-width-5-8 {
  width: 62.5%; }

.uk-width-large-6-8, .uk-width-medium-6-8, .uk-width-small-6-8, .uk-width-6-8 {
  width: 75%; }

.uk-width-large-7-8, .uk-width-medium-7-8, .uk-width-small-7-8, .uk-width-7-8 {
  width: 87.5%; }

.uk-width-large-8-8, .uk-width-medium-8-8, .uk-width-small-8-8, .uk-width-8-8 {
  width: 100%; }

/* Gutterless grid */
.uk-grid-gutter-remove .uk-grid > * {
  padding-left: 0 !important; }

﻿
.fancybox-wrap, .fancybox-skin, .fancybox-outer, .fancybox-inner, .fancybox-image, .fancybox-wrap iframe, .fancybox-wrap object, .fancybox-nav, .fancybox-nav span, .fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("//cdnjs.cloudflare.com/ajax/libs/fancybox/2.1.4/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: .8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("//cdnjs.cloudflare.com/ajax/libs/fancybox/2.1.4/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("//cdnjs.cloudflare.com/ajax/libs/fancybox/2.1.4/blank.gif");
  -webkit-tap-highlight-color: transparent;
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

.fancybox-lock {
  overflow: hidden; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("//cdnjs.cloudflare.com/ajax/libs/fancybox/2.1.4/fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

#fancybox-buttons {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 8050; }

#fancybox-buttons.top {
  top: 10px; }

#fancybox-buttons.bottom {
  bottom: 10px; }

#fancybox-buttons ul {
  display: block;
  width: 166px;
  height: 30px;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  border: 1px solid #111;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05);
  -moz-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05);
  background: #323232;
  background: -moz-linear-gradient(top, #444 0, #343434 50%, #292929 50%, #333 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #444), color-stop(50%, #343434), color-stop(50%, #292929), color-stop(100%, #333));
  background: -webkit-linear-gradient(top, #444 0, #343434 50%, #292929 50%, #333 100%);
  background: -o-linear-gradient(top, #444 0, #343434 50%, #292929 50%, #333 100%);
  background: -ms-linear-gradient(top, #444 0, #343434 50%, #292929 50%, #333 100%);
  background: linear-gradient(top, #444 0, #343434 50%, #292929 50%, #333 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#444444',endColorstr='#222222',GradientType=0); }

#fancybox-buttons ul li {
  float: left;
  margin: 0;
  padding: 0; }

#fancybox-buttons a {
  display: block;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  background-image: url("//cdnjs.cloudflare.com/ajax/libs/fancybox/2.1.4/helpers/fancybox_buttons.png");
  background-repeat: no-repeat;
  outline: 0;
  opacity: .8; }

#fancybox-buttons a:hover {
  opacity: 1; }

#fancybox-buttons a.btnPrev {
  background-position: 5px 0; }

#fancybox-buttons a.btnNext {
  background-position: -33px 0;
  border-right: 1px solid #3e3e3e; }

#fancybox-buttons a.btnPlay {
  background-position: 0 -30px; }

#fancybox-buttons a.btnPlayOn {
  background-position: -30px -30px; }

#fancybox-buttons a.btnToggle {
  background-position: 3px -60px;
  border-left: 1px solid #111;
  border-right: 1px solid #3e3e3e;
  width: 35px; }

#fancybox-buttons a.btnToggleOn {
  background-position: -27px -60px; }

#fancybox-buttons a.btnClose {
  border-left: 1px solid #111;
  width: 35px;
  background-position: -56px 0; }

#fancybox-buttons a.btnDisabled {
  opacity: .4;
  cursor: default; }

#fancybox-thumbs {
  position: fixed;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 8050; }

#fancybox-thumbs.bottom {
  bottom: 2px; }

#fancybox-thumbs.top {
  top: 2px; }

#fancybox-thumbs ul {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0; }

#fancybox-thumbs ul li {
  float: left;
  padding: 1px;
  opacity: .5; }

#fancybox-thumbs ul li.active {
  opacity: .75;
  padding: 0;
  border: 1px solid #fff; }

#fancybox-thumbs ul li:hover {
  opacity: 1; }

#fancybox-thumbs ul li a {
  display: block;
  position: relative;
  overflow: hidden;
  border: 1px solid #222;
  background: #111;
  outline: 0; }

#fancybox-thumbs ul li img {
  display: block;
  position: relative;
  border: 0;
  padding: 0; }

/*****************************************************************
IMPORT COMPASS
- If you don't have Compass you can create your own mixins
for CSS3 or use bourbon etc.
******************************************************************/
/******************************************************************
IMPORT SHADOW TYPES
******************************************************************/
/******************************************************************
SHADOW MIXINS
******************************************************************/
/******************************************************************
SHADOW CLASSES
******************************************************************/
/* line 53, ../scss/shadows.scss */
.shadow.lifted {
  position: relative; }

/* line 19, ../scss/shadows.scss */
.shadow.lifted:before, .shadow.lifted:after {
  position: absolute;
  z-index: -2;
  content: ""; }

/* line 2, ../scss/_shadow-types.scss */
.shadow.lifted:before, .shadow.lifted:after {
  bottom: 12px;
  width: 50%;
  height: 55%;
  max-width: 300px;
  max-height: 100px;
  -webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7); }

/* line 11, ../scss/_shadow-types.scss */
.shadow.lifted:before {
  left: 10px;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  transform: rotate(-3deg); }

/* line 12, ../scss/_shadow-types.scss */
.shadow.lifted:after {
  right: 10px;
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  transform: rotate(3deg); }

/* line 53, ../scss/shadows.scss */
.shadow.raised {
  position: relative;
  -webkit-box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5); }

/* line 19, ../scss/shadows.scss */
.shadow.raised:before, .shadow.raised:after {
  position: absolute;
  z-index: -2;
  content: ""; }

/* line 48, ../scss/_shadow-types.scss */
.shadow.raised:before, .shadow.raised:after {
  content: none; }

/* line 53, ../scss/shadows.scss */
.shadow.perspective {
  position: relative; }

/* line 19, ../scss/shadows.scss */
.shadow.perspective:before, .shadow.perspective:after {
  position: absolute;
  z-index: -2;
  content: ""; }

/* line 16, ../scss/_shadow-types.scss */
.shadow.perspective:before {
  left: 80px;
  bottom: 5px;
  width: 50%;
  height: 35%;
  max-width: 200px;
  max-height: 50px;
  -webkit-box-shadow: -80px 0 8px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: -80px 0 8px rgba(0, 0, 0, 0.4);
  box-shadow: -80px 0 8px rgba(0, 0, 0, 0.4);
  -webkit-transform: skew(50deg, 0);
  -moz-transform: skew(50deg, 0);
  -ms-transform: skew(50deg, 0);
  -o-transform: skew(50deg, 0);
  transform: skew(50deg, 0);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%; }

/* line 26, ../scss/_shadow-types.scss */
.shadow.perspective:after {
  display: none;
  content: none; }

/* line 53, ../scss/shadows.scss */
.shadow.perspective-right {
  position: relative; }

/* line 19, ../scss/shadows.scss */
.shadow.perspective-right:before, .shadow.perspective-right:after {
  position: absolute;
  z-index: -2;
  content: ""; }

/* line 30, ../scss/_shadow-types.scss */
.shadow.perspective-right:before {
  right: 80px;
  bottom: 5px;
  width: 50%;
  height: 35%;
  max-width: 200px;
  max-height: 50px;
  -webkit-box-shadow: 80px 0 8px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 80px 0 8px rgba(0, 0, 0, 0.4);
  box-shadow: 80px 0 8px rgba(0, 0, 0, 0.4);
  -webkit-transform: skew(-50deg, 0);
  -moz-transform: skew(-50deg, 0);
  -ms-transform: skew(-50deg, 0);
  -o-transform: skew(-50deg, 0);
  transform: skew(-50deg, 0);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%; }

/* line 40, ../scss/_shadow-types.scss */
.shadow.perspective-right:after {
  display: none;
  content: none; }

/* line 53, ../scss/shadows.scss */
.shadow.curved {
  position: relative; }

/* line 19, ../scss/shadows.scss */
.shadow.curved:before, .shadow.curved:after {
  position: absolute;
  z-index: -2;
  content: ""; }

/* line 54, ../scss/_shadow-types.scss */
.shadow.curved:before {
  top: 1px;
  bottom: 0;
  left: 12px;
  right: 12px;
  -webkit-box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 150px/10px;
  border-radius: 150px/10px; }

/* line 53, ../scss/shadows.scss */
.shadow.curved-top {
  position: relative; }

/* line 19, ../scss/shadows.scss */
.shadow.curved-top:before, .shadow.curved-top:after {
  position: absolute;
  z-index: -2;
  content: ""; }

/* line 54, ../scss/_shadow-types.scss */
.shadow.curved-top:before {
  top: 1px;
  bottom: 0;
  left: 12px;
  right: 12px;
  -webkit-box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 150px/10px;
  border-radius: 150px/10px; }

/* line 67, ../scss/_shadow-types.scss */
.shadow.curved-top:before {
  bottom: 50%; }

/* line 53, ../scss/shadows.scss */
.shadow.curved-bottom {
  position: relative; }

/* line 19, ../scss/shadows.scss */
.shadow.curved-bottom:before, .shadow.curved-bottom:after {
  position: absolute;
  z-index: -2;
  content: ""; }

/* line 54, ../scss/_shadow-types.scss */
.shadow.curved-bottom:before {
  top: 1px;
  bottom: 0;
  left: 12px;
  right: 12px;
  -webkit-box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 150px/10px;
  border-radius: 150px/10px; }

/* line 75, ../scss/_shadow-types.scss */
.shadow.curved-bottom:before {
  top: 50%; }

/* line 53, ../scss/shadows.scss */
.shadow.curved-vertical {
  position: relative; }

/* line 19, ../scss/shadows.scss */
.shadow.curved-vertical:before, .shadow.curved-vertical:after {
  position: absolute;
  z-index: -2;
  content: ""; }

/* line 81, ../scss/_shadow-types.scss */
.shadow.curved-vertical:before {
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0%;
  -webkit-box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 10px/100px;
  border-radius: 10px/100px; }

/* line 53, ../scss/shadows.scss */
.shadow.curved-vertical-left {
  position: relative; }

/* line 19, ../scss/shadows.scss */
.shadow.curved-vertical-left:before, .shadow.curved-vertical-left:after {
  position: absolute;
  z-index: -2;
  content: ""; }

/* line 81, ../scss/_shadow-types.scss */
.shadow.curved-vertical-left:before {
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0%;
  -webkit-box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 10px/100px;
  border-radius: 10px/100px; }

/* line 94, ../scss/_shadow-types.scss */
.shadow.curved-vertical-left:before {
  right: 50%; }

/* line 53, ../scss/shadows.scss */
.shadow.curved-vertical-right {
  position: relative; }

/* line 19, ../scss/shadows.scss */
.shadow.curved-vertical-right:before, .shadow.curved-vertical-right:after {
  position: absolute;
  z-index: -2;
  content: ""; }

/* line 81, ../scss/_shadow-types.scss */
.shadow.curved-vertical-right:before {
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0%;
  -webkit-box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 10px/100px;
  border-radius: 10px/100px; }

/* line 102, ../scss/_shadow-types.scss */
.shadow.curved-vertical-right:before {
  left: 50%; }

/* line 63, ../scss/shadows.scss */
.shadow:not(.lifted):not(.raised):not(.perspective):not(.perspective-right):not(.curved):not(.curved-top):not(.curved-bottom):not(.curved-vertical):not(.curved-vertical-left):not(.curved-vertical-right) {
  position: relative; }

/* line 19, ../scss/shadows.scss */
.shadow:not(.lifted):not(.raised):not(.perspective):not(.perspective-right):not(.curved):not(.curved-top):not(.curved-bottom):not(.curved-vertical):not(.curved-vertical-left):not(.curved-vertical-right):before, .shadow:not(.lifted):not(.raised):not(.perspective):not(.perspective-right):not(.curved):not(.curved-top):not(.curved-bottom):not(.curved-vertical):not(.curved-vertical-left):not(.curved-vertical-right):after {
  position: absolute;
  z-index: -2;
  content: ""; }

/* line 2, ../scss/_shadow-types.scss */
.shadow:not(.lifted):not(.raised):not(.perspective):not(.perspective-right):not(.curved):not(.curved-top):not(.curved-bottom):not(.curved-vertical):not(.curved-vertical-left):not(.curved-vertical-right):before, .shadow:not(.lifted):not(.raised):not(.perspective):not(.perspective-right):not(.curved):not(.curved-top):not(.curved-bottom):not(.curved-vertical):not(.curved-vertical-left):not(.curved-vertical-right):after {
  bottom: 12px;
  width: 50%;
  height: 55%;
  max-width: 300px;
  max-height: 100px;
  -webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7); }

/* line 11, ../scss/_shadow-types.scss */
.shadow:not(.lifted):not(.raised):not(.perspective):not(.perspective-right):not(.curved):not(.curved-top):not(.curved-bottom):not(.curved-vertical):not(.curved-vertical-left):not(.curved-vertical-right):before {
  left: 10px;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  transform: rotate(-3deg); }

/* line 12, ../scss/_shadow-types.scss */
.shadow:not(.lifted):not(.raised):not(.perspective):not(.perspective-right):not(.curved):not(.curved-top):not(.curved-bottom):not(.curved-vertical):not(.curved-vertical-left):not(.curved-vertical-right):after {
  right: 10px;
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  transform: rotate(3deg); }

.hide {
  display: none; }

.hide.uk-show {
  display: block; }

/* Secure login page */
.login {
  background: #ffffff; }

/* Admin bar/panel */
#admin-panel {
  position: fixed;
  opacity: 0;
  display: none;
  left: 0;
  bottom: 0;
  z-index: 1001;
  width: 100%;
  min-height: 30px;
  background-color: #188cb7;
  color: #fff;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }

#admin-panel {
  line-height: 18px; }

/* Admin button */
#admin-button {
  position: fixed;
  right: 0;
  bottom: 20px;
  z-index: 1000;
  cursor: pointer;
  width: 50px;
  height: 50px;
  color: #fff;
  background: #188cb7;
  -webkit-border-top-left-radius: 10px;
  -moz-border-top-left-radius: 10px;
  border-top-left-radius: 10px;
  background-clip: padding-box;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-bottom-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-clip: padding-box;
  transition: all .3s ease-in-out; }

#admin-button a {
  color: #ffffff;
  font-size: 13px;
  display: block;
  font-weight: bold; }

#admin-button a[class^='uk-icon'] {
  line-height: 2.2; }

#admin-button i {
  font-size: 20px;
  line-height: 48px;
  margin: 0 10px; }

#admin-button a span {
  line-height: 30px;
  padding: 0 5px; }

#admin-button:hover {
  right: 0; }

/* Admin items */
#admin-panel .uk-subnav > li {
  line-height: 1 !important; }

#admin-panel .uk-subnav > li > a {
  color: rgba(255, 255, 255, 0.8) !important; }

#admin-panel .uk-subnav > li > a:hover {
  color: #ffffff !important; }

#admin-panel .uk-subnav-line > li:nth-child(n+2):before {
  margin-top: 9px;
  border-left: 1px solid white !important; }

/* Clndr Settings */
/* Element */
#calendar {
  position: relative;
  float: left;
  width: 100%;
  overflow: hidden; }

/* Calendar */
/* Controller */
.clndr-controls {
  position: relative; }

.clndr-control-button {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  width: 25px;
  height: 25px;
  top: 20px; }

.clndr-control-button.clndr-previous-button {
  right: 30px;
  background-position: 0 -64px; }

.clndr-control-button.clndr-previous-button:hover {
  background-position: 0 -64px; }

.clndr-control-button.clndr-next-button {
  background-position: -25px -64px;
  left: auto;
  right: 0px; }

.clndr-control-button.clndr-next-button:hover {
  background-position: -25px -64px; }

.clndr-today-button {
  cursor: pointer;
  color: #333;
  background: #fed24f; }

.clndr-today-button:hover {
  color: #fff;
  background: #fed24f; }

.clndr-controls .month {
  position: relative;
  text-align: center; }

/* Table display */
.clndr-table {
  margin: 10px 0; }

.clndr-table td {
  text-align: center;
  font-size: 13px;
  width: 14%;
  height: 32px;
  background: #edecec; }

.clndr-table .header-day {
  color: #0063ac;
  height: 32px; }

.clndr-table .day {
  color: #697279; }

.clndr-table .adjacent-month.last-month, .clndr-table .adjacent-month.next-month {
  background: #eee;
  color: #999; }

.clndr-table .today {
  background: #fed24f;
  color: #333; }

.clndr-table .event {
  background: #F15828;
  color: #fff; }

.clndr-table .header-day.first, .clndr-table .day.first {
  border-left: 0; }

.clndr-table .header-day.last, .clndr-table .day.last {
  border-right: 0; }

.clndr-table tr.last td {
  border-bottom: 0; }

/* Event listing */
.event-listing i[class^='uk-icon'] {
  line-height: 1.4; }

.event-listing .event-item {
  margin-left: 12px; }

.event-listing .event-icon {
  margin-right: 5px;
  float: left; }

.event-listing h3 {
  margin: 0 0 5px 0; }

li.pdf a, li.zip a, li.nr a {
  display: none; }

.post h3 {
  color: #66C7E2; }

/*
 * Design by Adnet Communications Inc.
 * Implementation by Adnet Communications Inc.
 * (c) 2016
 */
/* general */
* {
  box-sizing: border-box; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

a {
  color: #d76400;
  outline: none;
  text-decoration: none;
  transition: color 0.3s; }

a:hover {
  color: inherit;
  text-decoration: none; }

input {
  border-radius: 0; }

em {
  color: inherit; }

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600; }

h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600; }

/* uikit specific */
[class*=uk-icon-] {
  vertical-align: initial; }

figure.uk-overlay {
  border: 1px solid #545962; }

.uk-minwidth td {
  min-width: 120px; }

.uk-table th {
  background: #d76400;
  color: #fff; }

/* all pages */
body {
  background: #fff;
  color: #5a5e61;
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
  line-height: 27px;
  margin: 0; }

header {
  border-top: 40px solid #d76400;
  height: 252px;
  margin: 0 0 -252px;
  position: relative;
  width: 100%;
  z-index: 20; }

.subpage header {
  background: #fff;
  margin: 0; }

.wrapper {
  margin: auto;
  padding: 0 26px;
  position: relative;
  width: 1200px; }

header .wrapper {
  height: 100%; }

.logo {
  left: 0;
  position: absolute;
  transition: all 0.2s ease-in-out;
  top: 7px; }

.logo img {
  display: block; }

.stockinfo {
  background: #d76400;
  color: #fff;
  left: 0;
  position: absolute;
  top: -40px;
  width: 100%; }

.stockinfo ul {
  list-style-type: none;
  margin: 0;
  padding: 0 20px 0 0;
  text-align: right; }

.stockinfo ul li {
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 40px; }

.main-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 65px; }

.main-nav li {
  display: inline-block;
  position: relative; }

.main-nav li a {
  display: block;
  font-family: 'Poppins', sans-serif; }

.main-nav > li > a {
  color: #545962;
  font-size: 16px;
  font-weight: 500;
  line-height: 38px;
  padding: 0 20px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s; }

.main-nav > li:hover > a {
  color: #545962; }

.main-nav > li.uk-active > a {
  background: #d76400;
  border: 1px solid #f2a900;
  color: #fff; }

.main-nav li ul {
  background: #d76400;
  display: none;
  left: 0;
  padding: 10px;
  position: absolute;
  top: 100%; }

.main-nav li:hover > ul {
  display: inherit; }

.main-nav li ul li {
  list-style: none;
  padding: 6px 0; }

.main-nav li ul li a {
  color: #fff;
  font-size: 14px;
  height: auto;
  line-height: 20px;
  padding: 0 10px;
  text-align: left;
  width: 150px; }

.main-nav li ul li a:hover {
  color: #545962; }

.main-nav li ul li ul {
  left: 100%;
  top: 0; }

#slider {
  position: relative;
  width: 100%; }

#slider .slide {
  height: 760px;
  width: 100%; }

#slider * {
  transition: all 0.3s; }

.slide .wrapper {
  height: 100%; }

.slide .caption {
  background: rgba(0, 0, 0, 0.75);
  bottom: 120px;
  left: 10px;
  padding: 40px 36px;
  position: absolute; }

.slide .caption h2 {
  color: #fff;
  font-size: 60px;
  line-height: 1;
  margin: 0;
  text-transform: uppercase; }

.slide .caption h3 {
  color: #d76400;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  margin: 0;
  text-transform: none; }

.banner {
  height: 340px; }

.banner h2.header {
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 46px;
  padding: 125px 0 0;
  text-align: center;
  text-transform: uppercase; }

.intro {
  background: #282b2e;
  border-bottom: 10px solid #d76400;
  padding: 36px 0 0;
  text-align: center; }

.intro .col {
  padding: 0 100px; }

.intro h1 {
  color: #fff;
  font-size: 40px;
  line-height: 50px; }

.intro p {
  color: #d2d7dc;
  font-size: 18px;
  line-height: 26px; }

.intro .more {
  background: #d76400;
  color: #282b2e;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  margin: 10px 0 0;
  padding: 3px 42px 0;
  text-transform: uppercase; }

.feature {
  background: url(../img/feature_bg.jpg) no-repeat 50% 0/cover;
  padding: 290px 0 70px; }

.feature .grid {
  margin: 0 0 0 -20px;
  padding: 0 10px; }

.feature .col {
  float: left;
  padding: 0 20px 0 0;
  position: relative; }

.feature .col-1, .feature .col-3 {
  width: 25%; }

.feature .col-1 {
  left: -50%; }

.feature .col-2 {
  left: 25%;
  width: 50%; }

.feature .box {
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid #fff;
  text-align: center; }

.feature .box .top {
  border-bottom: 1px solid #fff; }

.feature .box .title {
  color: #fff;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  padding: 56px 0;
  text-transform: uppercase; }

.feature .box .more {
  color: #fff;
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  line-height: 60px;
  text-transform: uppercase; }

.feature .col-2 .box .top {
  padding: 0 0 20px; }

.feature .col-2 .box .title {
  padding: 28px 0; }

.feature .col-2 ul {
  color: #d2d7dc;
  margin: 0;
  padding: 0 30px 0 45px;
  text-align: left; }

.feature .col-2 ul li {
  margin: 0 0 10px; }

.l-news {
  background: #d76400;
  padding: 25px 0 35px; }

.l-news h2 {
  color: #fff;
  font-size: 34px;
  line-height: 40px;
  text-align: center; }

.l-news .entry {
  float: left;
  padding: 0 15px;
  text-align: center;
  width: 33.3%; }

.l-news .entry a {
  display: block; }

.l-news .date {
  color: #fff;
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 0 8px;
  text-align: left; }

.l-news .title {
  border-bottom: 2px solid #fff;
  color: #000;
  display: inline-block;
  font-size: 22px;
  line-height: 30px;
  padding: 0 0 20px;
  text-align: left; }

.l-news .more {
  background: #282b2e;
  color: #d76400;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  padding: 0 40px;
  text-align: center;
  text-transform: uppercase; }

.side-col {
  float: left;
  padding: 10px 0 40px;
  width: 20%; }

.main-col {
  float: right;
  min-height: 350px;
  padding: 74px 0 20px;
  width: 75%; }

.main-col h2.header {
  border-bottom: 1px solid #d76400;
  color: #282b2e;
  font-size: 36px;
  line-height: 40px;
  margin: 0 0 20px;
  padding: 0 0 8px; }

.side-nav {
  border-bottom: 8px solid #d76400;
  list-style-type: none;
  margin: 0;
  padding: 0; }

.side-nav li a {
  box-shadow: 0 1px #d2d7dc;
  color: #5a5e61;
  display: block;
  font-size: 16px;
  line-height: 20px;
  padding: 18px 24px; }

.side-nav li.uk-active a {
  background: #282b2e;
  color: #fff; }

.side-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.side-nav li ul li a {
  font-size: 14px;
  padding: 12px 24px 12px 32px; }

.side-nav li.uk-active ul li a {
  background: none;
  color: #5a5e61; }

.pre-footer {
  background: url(../img/pre_footer.jpg) no-repeat 50% 0/cover;
  height: 330px; }

.pre-footer-sub {
  background: url(../img/pre_footer_sub.jpg) no-repeat 50% 0/cover;
  color: #fff;
  font-size: 18px;
  height: 440px;
  line-height: 30px; }

.pre-footer-sub .wrapper {
  height: 100%; }

.pre-footer-sub .box {
  background: rgba(0, 0, 0, 0.47);
  padding: 35px 50px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 680px; }

footer {
  background: #282b2e;
  padding: 38px 0; }

footer .subscribe {
  background: #fff;
  color: #d76400;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 50px;
  padding: 0 22px;
  text-transform: uppercase; }

footer .col {
  float: left; }

footer .col-1 ul {
  list-style-type: none;
  margin: 30px 0 0;
  padding: 0; }

footer .col-1 ul li {
  display: inline-block; }

footer .col-1 ul li a {
  color: #fff;
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  padding: 0 17px;
  text-transform: uppercase; }

footer .col-1 ul li:first-child a {
  padding-left: 0; }

footer .col-1 ul li.uk-active a {
  color: #d76400; }

footer .col-2 {
  color: #fff;
  float: right;
  font-size: 17px;
  line-height: 26px;
  padding: 78px 0 0; }

/* mobile-nav */
.mobile-nav-btn {
  display: none;
  height: 50px;
  position: absolute;
  right: 30px;
  top: 38px;
  width: 50px; }

.mobile-nav-btn span {
  background: #333;
  display: inline-block;
  height: 3px;
  left: 11px;
  position: absolute;
  text-indent: -9999px;
  top: 22px;
  width: 25px; }

.mobile-nav-btn span:before, .mobile-nav-btn span:after {
  background-color: inherit;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  transition: 0.3s all;
  width: 100%; }

.mobile-nav-btn span:before {
  bottom: 9px; }

.mobile-nav-btn span:after {
  top: 9px; }

.mobile-nav-btn.is-clicked span {
  background: none; }

.mobile-nav-btn.is-clicked span:before {
  background: #333;
  bottom: 0;
  transform: rotate(45deg); }

.mobile-nav-btn.is-clicked span:after {
  background: #333;
  top: 0;
  transform: rotate(-45deg); }

.mobile-nav {
  background: #eee;
  border-left: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: 100%;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 260px;
  z-index: 30;
  transition: transform .3s 0s, visibility 0s .3s;
  transform: translateX(260px);
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform .3s 0s, visibility 0s .3s;
  -moz-transition: -moz-transform .3s 0s, visibility 0s .3s;
  -webkit-transform: translateX(260px);
  -moz-transform: translateX(260px);
  -ms-transform: translateX(260px);
  -o-transform: translateX(260px); }

.mobile-nav.mobile-nav-is-open {
  transform: translateX(0);
  visibility: visible;
  transition: transform .3s 0s, visibility 0s 0s;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  -webkit-transition: -webkit-transform .3s 0s, visibility 0s 0s;
  -moz-transition: -moz-transform .3s 0s, visibility 0s 0s;
  -webkit-overflow-scrolling: touch; }

.mobile-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.mobile-nav ul.nav li {
  box-shadow: 0 1px #ccc inset; }

.mobile-nav ul.nav li a {
  display: block;
  font-size: 16px;
  line-height: 50px;
  padding: 0 20px; }

.mobile-nav ul.nav li.has-sub a:after {
  content: '\f105';
  float: right;
  font-family: 'FontAwesome';
  font-size: 20px; }

.mobile-nav ul.nav li.has-sub.sub-open > a {
  color: #000; }

.mobile-nav ul.nav li.has-sub.sub-open a:after {
  content: '\f107'; }

.mobile-nav ul.nav li.has-sub ul.sub li a:after {
  content: ''; }

.mobile-nav ul.sub {
  display: none; }

.mobile-nav ul.sub li {
  padding: 9px 10px; }

.mobile-nav ul.sub li a {
  font-size: 14px;
  line-height: 20px; }

.mobile-nav ul.stock {
  color: #525252;
  font-size: 14px;
  line-height: 40px;
  overflow: hidden;
  padding: 0 20px; }

.mobile-nav ul.stock li {
  float: left;
  margin: 0 12px 0 0; }

.mobile-nav ul.stock li.close {
  margin: 0 8px 0 0; }

.mobile-nav ul.stock li.close a {
  color: inherit; }

.mobile-nav ul.stock li.close span:before {
  content: '\f00d';
  font-family: 'FontAwesome'; }

.mobile-nav ul.stock li.price {
  float: right;
  margin: 0; }

.mobile-nav ul.stock li a {
  display: inline-block;
  margin: 0 12px 0 0; }

.mobile-nav ul.copyright {
  box-shadow: 0 1px #ccc inset;
  color: #525252;
  font-size: 14px;
  line-height: 20px;
  padding: 14px 20px;
  text-align: center; }

/* Large desktop */
@media (max-width: 1200px) {
  .wrapper {
    width: 100%; }
  .logo {
    left: 20px; }
  .stockinfo ul li {
    font-size: 16px; }
  .main-nav {
    right: 8px; }
  .main-nav > li > a {
    font-size: 15px;
    padding: 0 14px; }
  #slider .slide {
    height: 650px; }
  .slide .caption {
    bottom: 90px;
    left: 20px; }
  .feature .box .title {
    font-size: 26px; }
  .l-news .title {
    font-size: 20px; }
  .pre-footer-sub .box {
    right: 20px; }
  footer {
    text-align: center; }
  footer .col {
    float: none;
    width: 100%; }
  footer .col-2 {
    padding: 20px 0 0; } }

/* Desktop and smaller */
@media (max-width: 960px) {
  body {
    font-size: 14px;
    line-height: 24px; }
  header {
    border-top: 8px solid #d76400; }
  header {
    height: 130px;
    margin: 0 0 -130px; }
  .logo img {
    width: 200px; }
  .main-nav {
    display: none; }
  .mobile-nav-btn {
    display: block; }
  .stockinfo {
    display: none; }
  #slider .slide {
    height: 500px; }
  .slide .caption {
    bottom: 70px; }
  .slide .caption h2 {
    font-size: 46px; }
  .slide .caption h3 {
    font-size: 20px;
    line-height: 26px; }
  .banner {
    height: 260px; }
  .banner h2.header {
    padding: 110px 0 0; }
  .intro .col {
    padding: 0; }
  .intro h1 {
    font-size: 30px;
    line-height: 36px;
    margin: 0 0 20px; }
  .intro .more {
    font-size: 16px;
    line-height: 36px; }
  .intro p {
    font-size: 16px;
    line-height: 24px; }
  .feature {
    padding: 40px 0; }
  .feature .grid {
    margin: 0; }
  .feature .col {
    padding: 0; }
  .feature .col-1, .feature .col-3 {
    width: 48%; }
  .feature .col-1 {
    left: 0;
    margin: 0 4% 0 0; }
  .feature .col-2 {
    left: 0;
    margin: 0 0 40px;
    width: 100%; }
  .feature .box .title {
    font-size: 24px;
    padding: 40px 0; }
  .l-news h2 {
    font-size: 24px; }
  .l-news .title {
    font-size: 16px;
    line-height: 26px; }
  .pre-footer {
    height: 150px; }
  .main-col {
    float: none;
    min-height: auto;
    padding: 20px 0 40px;
    width: 100%; }
  .main-col h2.header {
    font-size: 30px;
    line-height: 36px; }
  .side-col {
    float: none;
    padding: 0 0 40px;
    width: 100%; }
  .pre-footer-sub {
    height: auto;
    font-size: 16px;
    line-height: 28px;
    padding: 40px 0; }
  .pre-footer-sub .box {
    position: relative;
    right: auto;
    top: auto;
    transform: none;
    width: 100%; }
  footer .subscribe {
    font-size: 16px;
    line-height: 40px; }
  footer .col-1 ul li a {
    font-size: 14px;
    padding: 0 10px; }
  footer .col-2 {
    font-size: 14px;
    line-height: 20px; } }

/* Phone landscape and smaller*/
@media (max-width: 767px) {
  #slider .slide {
    height: 360px; }
  .slide .caption {
    bottom: 20px; }
  .slide .caption h2 {
    font-size: 26px; }
  .slide .caption h3 {
    font-size: 14px;
    line-height: 18px; }
  .banner {
    height: 200px; }
  .banner h2.header {
    font-size: 36px;
    line-height: 44px;
    padding: 80px 0 0; }
  .intro h1 {
    font-size: 26px;
    line-height: 34px; }
  .intro .more {
    padding: 3px 30px 0; }
  .l-news {
    padding: 20px 0; }
  .l-news .entry {
    border-bottom: 1px solid #fff;
    float: none;
    margin: 0 0 20px;
    padding: 0 0 20px;
    text-align: left;
    width: 100%; }
  .l-news .entry:last-child {
    border: none;
    margin: 0; }
  .l-news .title {
    border: none;
    display: block;
    padding: 0 0 10px; }
  .l-news .more {
    padding: 0 20px; }
  .main-col h2.header {
    font-size: 26px;
    line-height: 34px; }
  .pre-footer {
    height: 100px; } }

/* Phone portrait and smaller*/
@media (max-width: 480px) {
  .wrapper {
    padding: 0 20px; }
  .feature {
    padding: 20px 0; }
  .feature .grid {
    padding: 0; }
  .feature .col {
    margin: 0 0 20px; }
  .feature .col-1, .feature .col-3 {
    width: 100%; }
  .feature .box .title {
    padding: 30px 0; }
  .l-news .date {
    font-size: 16px;
    line-height: 22px; }
  .banner {
    height: 160px; }
  .banner h2.header {
    font-size: 26px;
    line-height: 34px;
    padding: 66px 0 0; }
  .pre-footer-sub .box {
    padding: 20px 30px; }
  footer .col-1 ul li a {
    padding: 0 6px; } }

@media print {
  * {
    color: black !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important; }
  table tr,
  table th {
    border-bottom: 1px solid #000; }
  table th {
    background: #ccc; }
  #header-nav,
  .page-title-bg,
  body.bg {
    background: none; }
  h1, h2, h3, h4, h5, h6 {
    line-height: normal; }
  .page-title-bg,
  #page-logo {
    position: static;
    height: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    line-height: normal; }
  #page-header-quotes {
    position: absolute;
    right: 0;
    top: 25px; }
  .page-title-bg h1,
  .page-title-bg h2 {
    line-height: normal; }
  .dual-col-content .uk-panel .content-padding,
  .dual-col-content {
    min-height: 0;
    padding: 0; }
  #logo h1,
  #main-nav,
  #header-nav,
  #page-header-nav,
  #page-subnav,
  .slide,
  .dual-col-img,
  .uk-offcanvas,
  .uk-hidden-print,
  .header-img {
    display: none; }
  #page {
    padding: 10px 0; }
  #page-header,
  #page-footer,
  #page-header {
    margin: 20px 0 0;
    border-bottom: 1px solid #000; }
  #page-title h2 {
    line-height: normal; }
  .uk-panel {
    min-height: 0; }
  #page-footer-credit span,
  .print {
    display: block;
    text-indent: 0; }
  #page-aside {
    display: none; }
  #page-content.col {
    width: 100%; } }
