/*
 * Design by Adnet Communications Inc.
 * Implementation by Adnet Communications Inc.
 * (c) 2016
 */

 /* general */
* {box-sizing: border-box;}
html {-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;}
a {color: #d76400; outline: none; text-decoration: none; transition: color 0.3s;}
a:hover {color: inherit; text-decoration: none;}
input {border-radius: 0;}
em {color: inherit;}
h1 {font-family: 'Poppins', sans-serif; font-weight: 600;}
h2 {font-family: 'Poppins', sans-serif; font-weight: 600;}

/* uikit specific */
[class*=uk-icon-] {vertical-align: initial;}
figure.uk-overlay {border: 1px solid #545962;}
.uk-minwidth td {min-width: 120px;}
.uk-table th {background: #d76400; color: #fff;}

/* all pages */
body {background: #fff; color: #5a5e61; font-family: 'Titillium Web', sans-serif; font-size: 16px; line-height: 27px; margin: 0;}
header {border-top: 40px solid #d76400; height: 252px; margin: 0 0 -252px; position: relative; width: 100%; z-index: 20;}
.subpage header {background: #fff; margin: 0;}
.wrapper {margin: auto; padding: 0 26px; position: relative; width: 1200px;}
header .wrapper {height: 100%;}
.logo {left: 0; position: absolute; transition: all 0.2s ease-in-out; top: 7px;}
.logo img {display: block;}
.stockinfo {background: #d76400; color: #fff; left: 0; position: absolute; top: -40px; width: 100%;}
.stockinfo ul {list-style-type: none; margin: 0; padding: 0 20px 0 0; text-align: right;}
.stockinfo ul li {display: inline-block; font-family: 'Poppins', sans-serif; font-size: 18px; font-weight: 600; line-height: 40px;}

.main-nav {list-style-type: none; margin: 0; padding: 0; position: absolute; right: 0; top: 65px;}
.main-nav li {display: inline-block; position: relative;}
.main-nav li a {display: block; font-family: 'Poppins', sans-serif;}
.main-nav > li > a {color: #545962; font-size: 16px; font-weight: 500; line-height: 38px; padding: 0 20px; text-align: center; text-transform: uppercase; transition: all 0.3s;}
.main-nav > li:hover > a {color: #545962;}
.main-nav > li.uk-active > a {background: #d76400; border: 1px solid #f2a900; color: #fff;}
.main-nav li ul {background: #d76400; display: none; left: 0; padding: 10px; position: absolute; top: 100%;}
.main-nav li:hover > ul {display: inherit;}
.main-nav li ul li {list-style: none; padding: 6px 0;}
.main-nav li ul li a {color: #fff; font-size: 14px; height: auto; line-height: 20px; padding: 0 10px; text-align: left; width: 150px;}
.main-nav li ul li a:hover {color: #545962;}
.main-nav li ul li ul {left: 100%; top: 0;}

#slider {position: relative; width: 100%;}
#slider .slide {height: 760px; width: 100%;}
#slider * {transition: all 0.3s;}
.slide .wrapper {height: 100%;}
.slide .caption {background: rgba(0,0,0,.75); bottom: 120px; left: 10px; padding: 40px 36px; position: absolute;}
.slide .caption h2 {color: #fff; font-size: 60px; line-height: 1; margin: 0; text-transform: uppercase;}
.slide .caption h3 {color: #d76400; font-size: 30px; font-weight: 500; line-height: 36px; margin: 0; text-transform: none;}
.banner {height: 340px;}
.banner h2.header {color: #fff; font-family: 'Poppins', sans-serif; font-size: 40px; font-weight: 600; line-height: 46px; padding: 125px 0 0; text-align: center; text-transform: uppercase;}

.main-home {}
.intro {background: #282b2e; border-bottom: 10px solid #d76400; padding: 36px 0 0; text-align: center;}
.intro .col {padding: 0 100px;}
.intro h1 {color: #fff; font-size: 40px; line-height: 50px;}
.intro p {color: #d2d7dc; font-size: 18px; line-height: 26px;}
.intro .more {background: #d76400; color: #282b2e; display: inline-block; font-family: 'Poppins', sans-serif; font-size: 20px; font-weight: 600; line-height: 40px; margin: 10px 0 0; padding: 3px 42px 0; text-transform: uppercase;}
.feature {background: url(../img/feature_bg.jpg) no-repeat 50% 0 / cover; padding: 290px 0 70px;}
.feature .grid {margin: 0 0 0 -20px; padding: 0 10px;}
.feature .col {float: left; padding: 0 20px 0 0; position: relative;}
.feature .col-1, .feature .col-3 {width: 25%;}
.feature .col-1 {left: -50%;}
.feature .col-2 {left: 25%; width: 50%;}
.feature .box {background: rgba(0,0,0,.4); border: 1px solid #fff; text-align: center;}
.feature .box .top {border-bottom: 1px solid #fff;}
.feature .box .title {color: #fff; display: inline-block; font-family: 'Poppins', sans-serif; font-size: 30px; font-weight: 600; line-height: 36px; padding: 56px 0; text-transform: uppercase;}
.feature .box .more {color: #fff; display: block; font-family: 'Poppins', sans-serif; font-size: 20px; line-height: 60px; text-transform: uppercase;}
.feature .col-2 .box .top {padding: 0 0 20px;}
.feature .col-2 .box .title {padding: 28px 0;}
.feature .col-2 ul {color: #d2d7dc; margin: 0; padding: 0 30px 0 45px; text-align: left;}
.feature .col-2 ul li {margin: 0 0 10px;}
.l-news {background: #d76400; padding: 25px 0 35px;}
.l-news h2 {color: #fff; font-size: 34px; line-height: 40px; text-align: center;}
.l-news .entry {float: left; padding: 0 15px; text-align: center; width: 33.3%;}
.l-news .entry a {display: block;}
.l-news .date {color: #fff; display: block; font-family: 'Poppins', sans-serif; font-size: 20px; font-weight: 600; line-height: 26px; margin: 0 0 8px; text-align: left;}
.l-news .title {border-bottom: 2px solid #fff; color: #000; display: inline-block; font-size: 22px; line-height: 30px; padding: 0 0 20px; text-align: left;}
.l-news .more {background: #282b2e; color: #d76400; display: inline-block; font-family: 'Poppins', sans-serif; font-size: 16px; font-weight: 600; line-height: 40px; padding: 0 40px; text-align: center; text-transform: uppercase;}
.side-col {float: left; padding: 10px 0 40px; width: 20%;}
.main-col {float: right; min-height: 350px; padding: 74px 0 20px; width: 75%;}
.main-col h2.header {border-bottom: 1px solid #d76400; color: #282b2e; font-size: 36px; line-height: 40px; margin: 0 0 20px; padding: 0 0 8px;}
.side-nav {border-bottom: 8px solid #d76400; list-style-type: none; margin: 0; padding: 0;}
.side-nav li a {box-shadow: 0 1px #d2d7dc; color: #5a5e61; display: block; font-size: 16px; line-height: 20px; padding: 18px 24px;}
.side-nav li.uk-active a {background: #282b2e; color: #fff;}
.side-nav ul {list-style-type: none; margin: 0; padding: 0;}
.side-nav li ul li a {font-size: 14px; padding: 12px 24px 12px 32px;}
.side-nav li.uk-active ul li a {background: none; color: #5a5e61;}

.pre-footer {background: url(../img/pre_footer.jpg) no-repeat 50% 0 / cover; height: 330px;}
.pre-footer-sub {background: url(../img/pre_footer_sub.jpg) no-repeat 50% 0 / cover; color: #fff; font-size: 18px; height: 440px; line-height: 30px;}
.pre-footer-sub .wrapper {height: 100%;}
.pre-footer-sub .box {background: rgba(0,0,0,.47); padding: 35px 50px; position: absolute; right: 10px; top: 50%; transform: translateY(-50%); width: 680px;}
footer {background: #282b2e; padding: 38px 0;}
footer .subscribe {background: #fff; color: #d76400; display: inline-block; font-family: 'Poppins', sans-serif; font-size: 18px; font-weight: 600; line-height: 50px; padding: 0 22px; text-transform: uppercase;}
footer .col {float: left;}
footer .col-1 ul {list-style-type: none; margin: 30px 0 0; padding: 0;}
footer .col-1 ul li {display: inline-block;}
footer .col-1 ul li a {color: #fff; display: inline-block; font-size: 16px; line-height: 22px; padding: 0 17px; text-transform: uppercase;}
footer .col-1 ul li:first-child a {padding-left: 0;}
footer .col-1 ul li.uk-active a {color: #d76400;}
footer .col-2 {color: #fff; float: right; font-size: 17px; line-height: 26px; padding: 78px 0 0;}


/* mobile-nav */
.mobile-nav-btn {display: none; height: 50px; position: absolute; right: 30px; top: 38px; width: 50px;}
.mobile-nav-btn span {background: #333; display: inline-block; height: 3px; left: 11px; position: absolute; text-indent: -9999px; top: 22px; width: 25px;}
.mobile-nav-btn span:before, .mobile-nav-btn span:after {background-color: inherit; content: ''; height: 100%; left: 0; position: absolute; transition: 0.3s all; width: 100%;}
.mobile-nav-btn span:before {bottom: 9px;}
.mobile-nav-btn span:after {top: 9px;}
.mobile-nav-btn.is-clicked span {background: none;}
.mobile-nav-btn.is-clicked span:before {background: #333; bottom: 0; transform: rotate(45deg);}
.mobile-nav-btn.is-clicked span:after {background: #333; top: 0; transform: rotate(-45deg);}
.mobile-nav {background: #eee; border-left: 1px solid #ccc; box-shadow: 0 0 10px rgba(0,0,0,.3); height: 100%; overflow-y: auto; position: fixed; right: 0; top: 0; visibility: hidden; width: 260px; z-index: 30;
	transition: transform .3s 0s, visibility 0s .3s; transform: translateX(260px);
	-webkit-transform: translateZ(0); -webkit-backface-visibility: hidden; -webkit-transition: -webkit-transform .3s 0s, visibility 0s .3s; -moz-transition: -moz-transform .3s 0s, visibility 0s .3s; -webkit-transform: translateX(260px); -moz-transform: translateX(260px); -ms-transform: translateX(260px); -o-transform: translateX(260px);}
.mobile-nav.mobile-nav-is-open {transform: translateX(0); visibility: visible; transition: transform .3s 0s, visibility 0s 0s;
	-webkit-transform: translateX(0); -moz-transform: translateX(0); -ms-transform: translateX(0); -o-transform: translateX(0);
	-webkit-transition: -webkit-transform .3s 0s, visibility 0s 0s; -moz-transition: -moz-transform .3s 0s, visibility 0s 0s; -webkit-overflow-scrolling: touch;}
.mobile-nav ul {list-style-type: none; margin: 0; padding: 0;}
.mobile-nav ul.nav li {box-shadow: 0 1px #ccc inset;}
.mobile-nav ul.nav li a {display: block; font-size: 16px; line-height: 50px; padding: 0 20px;}
.mobile-nav ul.nav li.has-sub a:after {content: '\f105'; float: right; font-family: 'FontAwesome'; font-size: 20px;}
.mobile-nav ul.nav li.has-sub.sub-open > a {color: #000;}
.mobile-nav ul.nav li.has-sub.sub-open a:after {content: '\f107';}
.mobile-nav ul.nav li.has-sub ul.sub li a:after {content: '';}
.mobile-nav ul.sub {display: none;}
.mobile-nav ul.sub li {padding: 9px 10px;}
.mobile-nav ul.sub li a {font-size: 14px; line-height: 20px;}
.mobile-nav ul.stock {color: #525252; font-size: 14px; line-height: 40px; overflow: hidden; padding: 0 20px;}
.mobile-nav ul.stock li {float: left; margin: 0 12px 0 0;}
.mobile-nav ul.stock li.close {margin: 0 8px 0 0;}
.mobile-nav ul.stock li.close a {color: inherit;}
.mobile-nav ul.stock li.close span:before {content: '\f00d'; font-family: 'FontAwesome';}
.mobile-nav ul.stock li.price {float: right; margin: 0;}
.mobile-nav ul.stock li a {display: inline-block; margin: 0 12px 0 0;}
.mobile-nav ul.copyright {box-shadow: 0 1px #ccc inset; color: #525252; font-size: 14px; line-height: 20px; padding: 14px 20px; text-align: center;}

/* Large desktop */
@media (max-width: 1200px) {
.wrapper {width: 100%;}
.logo {left: 20px;}
.stockinfo ul li {font-size: 16px;}
.main-nav {right: 8px;}
.main-nav > li > a {font-size: 15px; padding: 0 14px;}
#slider .slide {height: 650px;}
.slide .caption {bottom: 90px; left: 20px;}
.feature .box .title {font-size: 26px;}
.l-news .title {font-size: 20px;}
.pre-footer-sub .box {right: 20px;}
footer {text-align: center;}
footer .col {float: none; width: 100%;}
footer .col-2 {padding: 20px 0 0;}
}

/* Desktop and smaller */
@media (max-width: 960px) {
body {font-size: 14px; line-height: 24px;}
header {border-top: 8px solid #d76400;}
header {height: 130px; margin: 0 0 -130px;}
.logo img {width: 200px;}
.main-nav {display: none;}
.mobile-nav-btn {display: block;}
.stockinfo {display: none;}
#slider .slide {height: 500px;}
.slide .caption {bottom: 70px;}
.slide .caption h2 {font-size: 46px;}
.slide .caption h3 {font-size: 20px; line-height: 26px;}
.banner {height: 260px;}
.banner h2.header {padding: 110px 0 0;}
.intro .col {padding: 0;}
.intro h1 {font-size: 30px; line-height: 36px; margin: 0 0 20px;}
.intro .more {font-size: 16px; line-height: 36px;}
.intro p {font-size: 16px; line-height: 24px;}
.feature {padding: 40px 0;}
.feature .grid {margin: 0;}
.feature .col {padding: 0;}
.feature .col-1, .feature .col-3 {width: 48%;}
.feature .col-1 {left: 0; margin: 0 4% 0 0;}
.feature .col-2 {left: 0; margin: 0 0 40px; width: 100%;}
.feature .box .title {font-size: 24px; padding: 40px 0;}
.l-news h2 {font-size: 24px;}
.l-news .title {font-size: 16px; line-height: 26px;}
.pre-footer {height: 150px;}
.main-col {float: none; min-height: auto; padding: 20px 0 40px; width: 100%;}
.main-col h2.header {font-size: 30px; line-height: 36px;}
.side-col {float: none; padding: 0 0 40px; width: 100%;}
.pre-footer-sub {height: auto; font-size: 16px; line-height: 28px; padding: 40px 0;}
.pre-footer-sub .box {position: relative; right: auto; top: auto; transform: none; width: 100%;}
footer .subscribe {font-size: 16px; line-height: 40px;}
footer .col-1 ul li a {font-size: 14px; padding: 0 10px;}
footer .col-2 {font-size: 14px; line-height: 20px;}
}


/* Phone landscape and smaller*/
@media (max-width: 767px) {
#slider .slide {height: 360px;}
.slide .caption {bottom: 20px;}
.slide .caption h2 {font-size: 26px;}
.slide .caption h3 {font-size: 14px; line-height: 18px;}
.banner {height: 200px;}
.banner h2.header {font-size: 36px; line-height: 44px; padding: 80px 0 0;}
.intro h1 {font-size: 26px; line-height: 34px;}
.intro .more {padding: 3px 30px 0;}
.l-news {padding: 20px 0;}
.l-news .entry {border-bottom: 1px solid #fff; float: none; margin: 0 0 20px; padding: 0 0 20px; text-align: left; width: 100%;}
.l-news .entry:last-child {border: none; margin: 0;}
.l-news .title {border: none; display: block; padding: 0 0 10px;}
.l-news .more {padding: 0 20px;}
.main-col h2.header {font-size: 26px; line-height: 34px;}
.pre-footer {height: 100px;}
}

/* Phone portrait and smaller*/
@media (max-width: 480px) {
.wrapper {padding: 0 20px;}
.feature {padding: 20px 0;}
.feature .grid {padding: 0;}
.feature .col {margin: 0 0 20px;}
.feature .col-1, .feature .col-3 {width: 100%;}
.feature .box .title {padding: 30px 0;}
.l-news .date {font-size: 16px; line-height: 22px;}
.banner {height: 160px;}
.banner h2.header {font-size: 26px; line-height: 34px; padding: 66px 0 0;}
.pre-footer-sub .box {padding: 20px 30px;}
footer .col-1 ul li a {padding: 0 6px;}
}
