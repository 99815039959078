@media print {
   * {color: black !important; text-shadow: none !important; filter: none !important; -ms-filter: none !important}
   table tr,
   table th {border-bottom: 1px solid #000}
   table th {background: #ccc}
   #header-nav,
   .page-title-bg,
   body.bg {background: none}
   h1, h2, h3, h4, h5, h6 {line-height: normal}
   .page-title-bg,
   #page-logo {position: static; height: auto; top: 0; right: 0; bottom: 0; left: 0; line-height: normal}
   #page-header-quotes {position: absolute; right: 0; top: 25px}
   .page-title-bg h1,
   .page-title-bg h2 {line-height: normal}

   .dual-col-content .uk-panel .content-padding,
   .dual-col-content {min-height: 0; padding: 0}

   #logo h1,
   #main-nav,
   #header-nav,
   #page-header-nav,
   #page-subnav,
   .slide,
   .dual-col-img,
   .uk-offcanvas,
   .uk-hidden-print,
   .header-img {display: none}
   #page {padding: 10px 0}
   #page-header,
   #page-footer,
   #page-header {margin: 20px 0 0; border-bottom: 1px solid #000}
   #page-title h2 {line-height: normal}
   .uk-panel {min-height: 0}
   #page-footer-credit span,
   .print {display: block; text-indent: 0}
   #page-aside {display: none}
   #page-content.col {width: 100%}
}